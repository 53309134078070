import React from "react"
import { Link } from "gatsby"

const NewsList = props => {
  const posts = props.post
  let preYear, pcYearFlg
  //投稿idでソート
  posts.sort(function(a, b) {
    if (a.node._meta.uid > b.node._meta.uid) {
      return -1
    } else {
      return 1
    }
  })

  const Item = posts.map((v, i) => {
    const link = v.node._meta.uid
    const cate = v.node.category
    const textJa = v.node.title_ja[0].text
    const textEn = v.node.title_en[0].text
    //PCの時、投稿が前の投稿と同じ年なら年(ex:2020)を表示しない
    const [Y, M, D] = v.node.date.split("-").map(x => Number(x))
    pcYearFlg = preYear !== Y ? true : false
    preYear = Y

    return (
      <li key={i} className="archive-body__item news-item" data-scroll="">
        {pcYearFlg && <span className="pc news-item__year">{Y}.</span>}
        <Link className="news-item__link" to={`/news/${link}`}>
          <div className="news-item__info">
            <span className="news-item__date en sp">
              {Y}.{M}.{D}
            </span>
            <span className="news-item__date en pc">
              {M}.{D}
            </span>
            <span className="news-item__category en">{cate}</span>
          </div>
          <h2 className="news-item__text">
            <span className="news-item__text-t en">{textEn}</span>
            <span className="news-item__text-t ja">{textJa}</span>
          </h2>
        </Link>
      </li>
    )
  })

  return <ul>{Item}</ul>
}

export default NewsList
