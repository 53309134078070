import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../../components/common/layout"
import Meta from "../../components/common/meta"
import PageTitle from "../../components/pageTitle/pageTitle"
import NewsList from "../../components/pages/newsArchive/NewsList"

const Pager = props => {
  const pageContext = props.pageContext
  const pageNum = pageContext.currentPage

  return (
    <div className="pager">
      {pageContext.hasPrevPath ? (
        <Link
          className="pager__arrow pager__arrow--prev"
          to={pageContext.prevPath}
        >
          Preview
        </Link>
      ) : (
        ""
      )}
      <ul className="pager__list">
        {pageContext.hasPrevPath ? (
          <li className="pager__item">
            <Link to={pageContext.prevPath} className="pager__link-num">
              {pageNum - 1}
            </Link>
          </li>
        ) : (
          ""
        )}
        <li className="pager__item is-current">
          <p className="pager__link-num">{pageNum}</p>
        </li>
        {pageContext.hasNextPath ? (
          <li className="pager__item">
            <Link to={pageContext.nextPath} className="pager__link-num">
              {pageNum + 1}
            </Link>
          </li>
        ) : (
          ""
        )}
      </ul>
      {pageContext.hasNextPath ? (
        <Link
          className="pager__arrow pager__arrow--next"
          to={pageContext.nextPath}
        >
          Next
        </Link>
      ) : (
        ""
      )}
    </div>
  )
}

const NewsPage = props => {
  const { pageContext } = props
  const check = props.data.prismic.allNewsarticles.edges.slice(0, 1).pop()
  if (!check) return null
  const posts = props.data.prismic.allNewsarticles.edges

  return (
    <Layout page="news-archive">
      <Meta
        title="news | 1moku co."
        description="hirofumi sugaを中心に庭園デザインをはじめ、庭師の視点でとらえた空間デザイン、ランドスケープデザインなどを手掛けている1moku co.の最新情報をご紹介します。"
        url="https://www.1moku.co.jp/news/"
        type="article"
      />
      <PageTitle title="news" />
      <div className="news-main news-main--archive">
        <div className="archive archive--news">
          <div className="archive__head archive-haead">
            <h1 className="archive-haead__in" data-scroll="">
              <span className="archive-haead__title en">News</span>
              <span className="archive-haead__title ja">ニュース</span>
            </h1>
          </div>
          <div className="archive__body archive-body">
            <NewsList post={posts} />
            <div className="archive-body__pager">
              <Pager pageContext={pageContext} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query newsArchiveQuery($limit: Int, $cursor: String) {
    prismic {
      allNewsarticles(first: $limit, after: $cursor, sortBy: date_DESC) {
        pageInfo {
          hasPreviousPage
          hasNextPage
        }
        edges {
          node {
            category
            date
            title_en
            title_ja
            _meta {
              uid
            }
          }
        }
      }
    }
  }
`

export default NewsPage
